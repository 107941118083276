<messages>["../Registrar"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-card class="search-result">
        <!-- toolbar for selected item operations -->

        <v-card-title primary-title>
          <v-row justify="space-between">
            <v-col cols="12" sm="6">
              <div>
                <div
                  v-t="'title.list'"
                  class="text-h5"/>
                <div
                  v-t="'title.listSub'"
                  class="cgwng-subheading"/>
              </div>
            </v-col>
            <!-- filter -->
            <v-col v-if="frontendListOperations" cols="12" sm="6">
              <v-text-field
                v-model.trim="search"
                append-icon="search"
                :label="$t ('general.label.search')"
                single-line
                hide-details
                clearable
                @keyup.esc="search = ''"/>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- list -->
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="registrars"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.rowsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :footer-props="footerProps"
            :server-items-length="totalCount"
            :loading="loading"
            :no-data-text="noDataText (loading)"
            class="elevation-1">
            <template #headerCell="props">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span
                    class="primary--text"
                    v-on="on">
                    {{ props.header.text }}
                  </span>
                </template>
                <span>{{ props.header.text }}</span>
              </v-tooltip>
            </template>
            <template #item="props">
              <tr>
                <td>
                  <router-link
                    :to="{name: 'registrar.view', params: {id: props.item.id}}"
                    v-text="props.item.name"/>
                </td>
                <td>{{ props.item.displayName }}</td>
                <td class="text-right">
                  {{ props.item.ianaId ? props.item.ianaId : EmptyMark }}
                </td>
                <td>
                  <action-buttons
                    :value="isActionButtonsActive (props.item.id)"
                    :buttons="getActionButtons (props.item)"
                    @input="state => setActionButtonsActive (props.item.id) (state)"
                    @clicked="processActionButton"/>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.refresh'"
            :to="{name: 'registrar.list'}"/>
          <v-btn
            v-t="'general.button.create'"
            color="primary"
            :to="{name: 'registrar.create'}"/>
        </v-card-actions>

        <!-- delete confirmation dialog -->
        <base-dialog
          v-if="showDeleteConfirmationDialog"
          v-model="showDeleteConfirmationDialog"
          mw0
          close-on-esc>
          <v-card>
            <v-card-title>
              <div
                class="text-h5"
                v-text="$t ('registrar.delete.title')"/>
            </v-card-title>
            <v-card-text
              v-text="$tc (
                'registrar.delete.message',
                itemsToDelete.length, {
                  id: itemsToDelete[0].id,
                  count: itemsToDelete.length
                })"/>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'general.button.cancel'"
                text
                @click.native="showDeleteConfirmationDialog = false"/>
              <v-btn
                v-t="'general.button.delete'"
                color="error"
                @click.native="deleteRegistrars ()"/>
            </v-card-actions>
          </v-card>
        </base-dialog>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapMutations} from 'vuex'
  import {EmptyMark} from '@/app/utils/string'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import BaseDialog from '@/app/core/components/BaseDialog'
  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    name: 'RegistrarList',

    components: {
      ActionButtons,
      BaseDialog,
      BaseLayout
    },

    mixins: [paginationMixins, actionButtonsHelper],

    data () {
      return {
        // flag, which allows list filter (search) and client-side
        // sorting/paging
        frontendListOperations: true,
        search: '',
        cachedRegistrars: [],
        registrars: [],
        totalCount: 0,
        speedDial: {},
        itemsMatchingSearch: [],
        itemsToDelete: [],
        showDeleteConfirmationDialog: false,
        loading: true,
        isLoadingResetMulti: false,
        EmptyMark
      }
    },

    computed: {
      cachedItems () {
        return this.cachedRegistrars
      },

      items () {
        return this.registrars
      },

      headers () {
        const headerItem = p => ({
          text: this.$t (`label.${p}`),
          value: p
        })

        return [
          headerItem ('name'),
          headerItem ('displayName'),
          {
            ...headerItem ('ianaId'),
            align: 'right',
            sortable: false
          },
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      }
    },

    watch: {
      search () {
        this.list ()
      }
    },

    created () {
      // this is a workaround to enforce data retrieve from BE
      // even if the pagination and other request parameters are not changed
      // (otherwise data are not fetched on page reload and browser "back" in history)
      // TODO, FIXME: need better solution to prevent request duplication
      this.onPaginationStateChanged (this.internalPaginationState)
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        return [
          {
            action: 'view',
            itemId: item.id,
            icon: 'visibility',
            tooltip: this.$t ('general.button.view')
          },
          {
            action: 'edit',
            itemId: item.id,
            icon: 'edit',
            tooltip: this.$t ('general.button.edit')
          }
          // {
          //   action: 'delete',
          //   actionArg: [item],
          //   icon: 'delete',
          //   disabled: true,
          //   tooltip: this.$t ('general.button.delete'),
          //   color: 'error'
          // }
        ]
      },

      list () {
        this.listRegistrars ()
      },

      async requestRegistrarList (params) {
        const data = await this.fetchData ({
          op: 'registrar/list',
          params
        })

        return [data.registrars || [], data.totalCount || 0]
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters (client-side filter, sorting, slicing)
       */
      performListOperation () {
        // filter
        if (this.search) {
          const searchNormalized = this.search.trim ().toLocaleLowerCase ()
          this.registrars = this.cachedRegistrars.filter (o => {
            let match
            for (const prop in o) {
              if (o.hasOwnProperty (prop)) {
                switch (typeof o[prop]) {
                  case 'string':
                    match =
                      o[prop].toLocaleLowerCase ().includes (searchNormalized)
                    break

                  case 'number':
                    match = o[prop] === Number.parseInt (searchNormalized)
                    break
                }

                if (match) {
                  break
                }
              }
            }
            return match
          })
        } else {
          this.registrars = this.cachedRegistrars.slice ()
        }
        this.totalCount = this.registrars.length
        // sort
        if (this.pagination.sortBy) {
          this.registrars = this.sort (
            this.registrars, this.pagination.sortBy, this.pagination.descending)
        }

        this.itemsMatchingSearch = [...this.registrars]

        // slice
        if (this.pagination.page > 0 && this.pagination.rowsPerPage > 0) {
          const start = (this.pagination.page - 1) * this.pagination.rowsPerPage
          const end = start + this.pagination.rowsPerPage
          this.registrars = this.registrars.slice (start, end)
        }
      },

      sort (items, column, descending) {
        if (descending === null) return items
        const collator = new Intl.Collator (
          undefined,
          {numeric: true, sensitivity: 'base'}
        )

        switch (column) {
          case 'name':
            return items.sort ((i1, i2) => descending
              ? collator.compare (i2.name, i1.name)
              : collator.compare (i1.name, i2.name))

          case 'displayName':
            return items.sort ((i1, i2) => descending
              ? collator.compare (i2.displayName, i1.displayName)
              : collator.compare (i1.displayName, i2.displayName))

          case 'emailAddress':
            return items.sort ((i1, i2) => {
              if (!i2.emailAddress) {
                return descending ? -1 : 1
              }
              if (!i1.emailAddress) {
                return descending ? 1 : -1
              }

              return descending
                ? i2.emailAddress.localeCompare (i1.emailAddress)
                : i1.emailAddress.localeCompare (i2.emailAddress)
            })

          case 'clientId':
            return items.sort ((i1, i2) => descending
              ? i2.clientId - i1.clientId
              : i1.clientId - i2.clientId)

          case 'actions':
            console.warn ('sorting by action is not supported')
            break
        }
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters. If {@code this.frontendListOperations} is set,
       * then operation is performed on the client side.
       */
      async listRegistrars () {
        this.loading = true

        if (this.frontendListOperations) {
          // if not cached => get from server
          if (!(this.cachedRegistrars.length > 0)) {
            [this.cachedRegistrars] = await this.requestRegistrarList ({})
            this.totalCount = this.cachedRegistrars.length
          }
          // filter, sorting, slicing cached items
          this.performListOperation ()
        } else {
          [this.registrars, this.totalCount] =
            await this.requestRegistrarList (this.getPaginationForRequest ())
        }

        this.loading = false
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        switch (button.action) {
          case 'view':
            this.$router.push ({
              name: 'registrar.view',
              params: {id: button.itemId}
            })
            break
          case 'edit':
            this.$router.push ({
              name: 'registrar.edit',
              params: {id: button.itemId}
            })
            break
          case 'delete':
            this.onDeleteRegistrars (button.actionArg)
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      /**
       * show delete confirmation dialog
       *
       * @param itemsToDelete   items to be deleted
       */
      onDeleteRegistrars (itemsToDelete) {
        this.itemsToDelete = itemsToDelete
        this.showDeleteConfirmationDialog = true
      },

      /**
       * delete registrars, specified by {@code this.itemsToDelete}
       * (see onDeleteRegistrars)
       *
       * @param successCallback     function to be called in success case
       */
      deleteRegistrars (successCallback = this.afterDelete) {
        // hide confirmation dialog
        this.showDeleteConfirmationDialog = false
        const ids = this.itemsToDelete.map (e => e.id)

        this.fetchData ({
          op: 'registrar/delete',
          params: {ids},
          cb: () => {
            this.displaySuccessMessage (
              this.$tc (
                'registrar.deleted',
                ids.length, {
                  id: ids[0],
                  count: ids.length
                }))
            successCallback (ids)
          }
        })
      },

      /**
       * state corrections after delete operation
       *
       * @param deletedIds    IDs of items, which was deleted
       */
      afterDelete (deletedIds) {
        // reset items to delete
        this.itemsToDelete = []
        // reset cache
        this.cachedRegistrars = []
        // get the list from server
        this.listRegistrars ()
      }
    }
  }
</script>
